
.container {
    margin-left: 0;
    margin-top: 12px;
}

.dataTable {
    display: grid;
    grid-template-columns: max(200px) 1fr;
	gap: 0px;
    border: 1px solid #e0e0e0;
}

@media only screen and (max-width: 600px) {
	.dataTable {
		display: block;
	}
}
 
.dataTable > * {
    padding: 8px;
    font-size: smaller;
    border: 1px solid #e0e0e0;
	border-collapse: collapse;
}

.sectionTitle {
    background-color: #f5f5f5;
    font-weight: bold;
}


.fhirTable {
    border-collapse: collapse;
    width: 100%;
}

.fhirTable td, .fhirTable th {
    text-align: left;
    vertical-align: middle;
    padding: 2px 8px 2px 2px;
    font-size: 100%;
    border-top: 1px solid lightgray;
}

.fhirTable th {
    border-top: none;
}

.narrative {
    background-color: whitesmoke;
    padding: 8px;
}

.narrative table {
    border-collapse: collapse;
    width: 100%;
}

.narrative th, .narrative td {
    padding: 4px;
    font-size: unset;
    border: 1px solid lightgray;
}

.narrative th {
    background-color: #f7f7f7;
    border-bottom: 2px solid #d0d0d0;
}

.patCell {
    font-weight: bold;
}

.toggleButton {
	float: right;
	margin-top: 6px;
}

@media only screen and (max-width: 600px) {
	.toggleButton {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: right;
		float: none;
		margin-top: 0px;
	}
}

.compositionSourceList {
	padding-left: 0;
    border: none;
    margin: 0;
    padding: 0;
}