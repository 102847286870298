
.container {
	display: grid;
	grid-template-columns: 200px 1fr;
	gap: 16px;
	max-width: 800px;
}

.cardImg {
	grid-row: 1;
	grid-column: 1;
	margin-top: 12px;
}

.content {
	grid-row: 1;
	grid-column: 2;
}

.deets {
	grid-column: 1 / 3;
	grid-row: 2;
	font-size: smaller;
}

@media (max-width: 800px) {
	
	.container {
		display: block;
	}
	
	.cardImg {
		display: none;
	}
}
