
.container {
	border: 1px solid grey;
	background-color: #f0f8ff;
	padding: 8px 8px 8px 8px;
	max-width: 640px;
}

.errors {
	float: right;
	font-size: smaller;
}

.green {
	font-weight: bold;
	color: green;
}

.yellow {
	font-weight: bold;
	color: gold;
}

.red {
	font-weight: bold;
	color: red;
}
